
import React from "react"

import Image from "../../Image"

export default ({size}) => (
    <Image 
        id="img-categories_lit-cabinets"
        alt="Lit Cabinet Products"
        backgroundColor={"#ffffff"}
        backgroundPreview={null}
        aspectRatio={48}  
        width={300} 
        height={145} 
        sizes={[100, 300]}     
        devices={['phone', 'desktop']}     
        webpImages={[
            "https://storage.googleapis.com/signmonkey-148101.appspot.com/2022/test/categories/lit-cabinets-100.webp?v25", "https://storage.googleapis.com/signmonkey-148101.appspot.com/2022/test/categories/lit-cabinets-300.webp?v25"
        ]}
        images={[
            "https://storage.googleapis.com/signmonkey-148101.appspot.com/2022/test/categories/lit-cabinets-100.jpg?v25", "https://storage.googleapis.com/signmonkey-148101.appspot.com/2022/test/categories/lit-cabinets-300.jpg?v25"
        ]}
    />   
)
