
import React from "react"

import Image from "../../Image"

export default ({size}) => (
    <Image 
        id="img-categories_accessories"
        alt="Roof Mounted Brackets & Other Sign Accessories"
        backgroundColor={"#ffffff"}
        backgroundPreview={null}
        aspectRatio={62}  
        width={300} 
        height={187.5} 
        sizes={[100, 300]}     
        devices={['phone', 'desktop']}     
        webpImages={[
            "https://storage.googleapis.com/signmonkey-148101.appspot.com/2022/test/categories/accessories-100.webp?v25", "https://storage.googleapis.com/signmonkey-148101.appspot.com/2022/test/categories/accessories-300.webp?v25"
        ]}
        images={[
            "https://storage.googleapis.com/signmonkey-148101.appspot.com/2022/test/categories/accessories-100.jpg?v25", "https://storage.googleapis.com/signmonkey-148101.appspot.com/2022/test/categories/accessories-300.jpg?v25"
        ]}
    />   
)
