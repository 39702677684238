
import React from "react"

import Image from "../../Image"

export default ({size}) => (
    <Image 
        id="img-categories_channel-letters-and-logos"
        alt="Lit Channel Letters and Logo Products"
        backgroundColor={"#ffffff"}
        backgroundPreview={null}
        aspectRatio={48}  
        width={300} 
        height={145} 
        sizes={[100, 300]}     
        devices={['phone', 'desktop']}     
        webpImages={[
            "https://storage.googleapis.com/signmonkey-148101.appspot.com/2022/test/categories/channel-letters-and-logos-100.webp?v25", "https://storage.googleapis.com/signmonkey-148101.appspot.com/2022/test/categories/channel-letters-and-logos-300.webp?v25"
        ]}
        images={[
            "https://storage.googleapis.com/signmonkey-148101.appspot.com/2022/test/categories/channel-letters-and-logos-100.jpg?v25", "https://storage.googleapis.com/signmonkey-148101.appspot.com/2022/test/categories/channel-letters-and-logos-300.jpg?v25"
        ]}
    />   
)
