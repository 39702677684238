import React from "react"
import { Link } from "gatsby"
import { FontAwesomeSvgIcon } from 'react-fontawesome-svg-icon';
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import styled from "styled-components";

import Page from "../components/layout/Page"
import SEO from "../components/seo"
import ProductBox from "../components/ProductBox"
import SeconddaryButton from "../components/buttons/SecondaryButton"

import { devices, storage, colors } from "../settings"

import ChannelLettersImage from "../components/images/categories/channel-letters-and-logos"
import LitShapesImage from "../components/images/categories/lit-shapes"
import CabinetsImage from "../components/images/categories/lit-cabinets"
import UnlitImage from "../components/images/categories/unlit"
import AccessoriesImage from "../components/images/categories/accessories"
import SubmitPriceRequestImage from "../components/images/submit-price-request";

const Products = styled.div`
  display: flex;
  justify-content: space-between;
  flex-flow: wrap;

  > div {
    flex-basis: 32%;

    display: flex;
    flex-flow: column;    
  }

  img {
    display: block;
    flex: 0;    
    max-width: 300px !important;
    margin: 10px auto;  
  }

  button {
    display: block;
    margin-top: 20px;
    margin-left: auto;
  }

  a.button {
    align-self: flex-end;
  }

  ul {
    list-style-type: none; 
    padding: 0px;
    margin: 0px;
    min-height: 95px;

    li {
      display: flex;
      align-items: center;
    }
    li span {
      display: inline-block;
      margin-right: 8px;
      color: ${colors.red};
      font-weight: bolder;
      font-size: 20px;
      line-height: 18px;
    }
  }

  @media ${devices.mobile} {
    display: block;
  }  
`

const ProductsPage = () => {
  return (
    <>
      <SEO page="products" title="Store Front Channel Letters & Lit Shape Signs" />
      <div
        style={{
          backgroundImage:"url("+storage.url+"2020/tiles/grey3.jpg)"
        }}
      >
        <Page 
          transparent
          breadcrumb={[
            {title: "Home", url:"/"},
            {title: "Products", url:null}
          ]}
        >
          <Products>
            <ProductBox>              
                <Link to="/products/lit-channel-letters-and-logos/">
                  <h2>Lit Channel Letters and Logos</h2>
                </Link>
                <Link to="/products/lit-channel-letters-and-logos/" className="image">
                  <ChannelLettersImage />
                </Link>
                <p>
                  Internally Lit with High Output LEDs. Designed for easy installation and electrical connections.
                </p>
                <ul style={{flex: 1}}>
                  <li><span>&bull;</span> Individual letters with logo shapes</li>
                  <li><span>&bull;</span> Common for store front or mall installations</li>
                  <li><span>&bull;</span> Variety of lighting effects</li>
                  <li><span>&bull;</span> Broad size range</li>
                </ul>
                <SeconddaryButton 
                  to="/products/lit-channel-letters-and-logos/" 
                  label={<><FontAwesomeSvgIcon icon={faChevronRight} />{' Learn More'}</>} 
                />
            </ProductBox>

            <ProductBox>
                <Link to="/products/lit-shapes/">
                  <h2>Lit Shape Signs</h2>
                </Link>
                <Link to="/products/lit-shapes/">
                  <LitShapesImage />
                </Link>
                <p>
                  Internally Lit with High Output LEDs. Self Contained electrical system for simple installation.
                </p>
                <ul style={{flex: 1}}>
                  <li><span>&bull;</span> One piece construction</li>
                  <li><span>&bull;</span> Custom details shapes</li>
                  <li><span>&bull;</span> Limited size to under 30 square feet</li>
                </ul>
                <SeconddaryButton to="/products/lit-shapes/" label={<><FontAwesomeSvgIcon icon={faChevronRight} />{' Learn More'}</>} />
            </ProductBox>

            <ProductBox>
                <Link to="/products/lit-cabinets/">
                  <h2>Lit Cabinet Signs</h2>
                </Link>
                <Link to="/products/lit-cabinets/">
                  <CabinetsImage />
                </Link>
                <p>
                  Internally Lit with High Output LEDs. Self Contained electrical system with multiple installation options.
                </p>
                <ul style={{flex: 1}}>
                  <li><span>&bull;</span> Highly durable and rigid construction</li>
                  <li><span>&bull;</span> Easily changeable plastic face</li>
                  <li><span>&bull;</span> Can be built very large</li>
                  <li><span>&bull;</span> Limited shape options</li>
                </ul>
                <SeconddaryButton  to="/products/lit-cabinets/" label={<><FontAwesomeSvgIcon icon={faChevronRight} />{' Learn More'}</>} />
            </ProductBox>

            <ProductBox>
                <Link to="/products/dimensional-letters-and-logos/">
                  <h2>Dimensional Letters &amp; Logo Shapes</h2>
                </Link>
                <Link to="/products/dimensional-letters-and-logos/">
                  <UnlitImage />
                </Link>
                <p>
                  Do not have internal illumination. Can be externally illuminated.  Sometimes used to compliment Channel Letters at 
                  a lower cost point. Can be easily installed variety of ways depending on wall construction.
                </p>
                <ul style={{flex: 1}}>
                  <li><span>&bull;</span> Highly durable and rigid construction</li>
                  <li><span>&bull;</span> Can be built very small</li>
                </ul>
                <SeconddaryButton to="/products/dimensional-letters-and-logos/" label={<><FontAwesomeSvgIcon icon={faChevronRight} />{' Learn More'}</>} />
            </ProductBox>        

            <ProductBox>
                <Link to="/sign-price-request/?from=CustomPriceBox">          
                  <h2>Submit Your Design for Price</h2>
                </Link>
                <p>
                  We can match your font, artwork or logo with the same easy installation and quality as our 
                  standard type styles and shapes.  All within 24 hours.
                </p>
                <Link to="/sign-price-request/?from=CustomPriceBox" style={{flex: 1}}>          
                  <SubmitPriceRequestImage />
                </Link>
                <SeconddaryButton to="/sign-price-request/?from=CustomPriceBox" label={<><FontAwesomeSvgIcon icon={faChevronRight} />{' Submit Design for Price'}</>} />
            </ProductBox> 

            <ProductBox>
              <Link to="/products/accessories/">
                <h2>Sign Accessories</h2>
              </Link>
              <p>
                  Items to aid installation of your sign.
              </p>
              <Link to="/products/accessories/" style={{flex: 1}}>          
                  <AccessoriesImage />
              </Link>

              <SeconddaryButton to="/products/accessories/" label="Browse Items" />
            </ProductBox> 
          </Products>
        </Page>
      </div>
    </>
  )
}

export default ProductsPage
